export const dateToFRFormat = (date: Date): string => {
  let formattedDate = ''

  if (date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.getTime())) {
    const annee = date.getFullYear().toString()
    const mois = date.getMonth() + 1 >= 10 ? (date.getMonth() + 1).toString() : '0' + (date.getMonth() + 1).toString()
    const jour = date.getDate() >= 10 ? date.getDate().toString() : '0' + date.getDate().toString()

    formattedDate = jour + '/' + mois + '/' + annee
  }

  return formattedDate
}

export const dateFRtoBasic = (dateFR: string): string => {
  if (!dateFR) return ''

  const day = dateFR.substr(0, 2)
  const month = dateFR.substr(3, 2)
  const year = dateFR.substr(6, 4)

  return year + '-' + month + '-' + day
}

export const capitalize = (str: string): string => {
  if (!str || str === null) return str

  const words = str.toLowerCase().split(/\b/)

  for (let i = 0; i < words.length; i++) {
    if (/^[a-zA-Z']+$/.test(words[i])) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
    }
  }

  return words.join('')
}

const userAgent: string = navigator.userAgent || navigator.vendor

export const isMobileDevice = (): boolean => {
  const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i]
  return regexs.some((b) => userAgent.match(b))
}

const isTabletDevice = (): boolean => {
  const regex =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
  return regex.test(userAgent.toLowerCase())
}

export const isDesktopDevice = (): boolean => !isMobileDevice() && !isTabletDevice()
