import { dateFRtoBasic, dateToFRFormat } from '@/utils/index'
import {
  ComplementaryInfos,
  GeneralInformations,
  LastMonthRevenue,
  EndingContractReportPayload,
  EndingContract,
  WorkStoppageReportPayload,
  WorkStoppage,
  REPORT_TYPE,
} from '@/models/reporting.model'

export class Helper {
  static storingDataFromAPI(report: EndingContractReportPayload): EndingContract {
    const reportForStore: EndingContract = {
      contractId: report.contratId!,
      generalInformations: {
        endingDate: typeof report.dateFin === 'string' ? new Date(dateFRtoBasic(report.dateFin)) : report.dateFin,
        endingReason: report.motifFin,
        lastDayWork:
          typeof report.dernierJourTravaille === 'string'
            ? new Date(dateFRtoBasic(report.dernierJourTravaille))
            : report.dernierJourTravaille,
        firingDate:
          typeof report.dateLicenciement === 'string'
            ? new Date(dateFRtoBasic(report.dateLicenciement))
            : report.dateLicenciement,
        conventionDate:
          typeof report.dateConvention === 'string'
            ? new Date(dateFRtoBasic(report.dateConvention))
            : report.dateConvention,
        notificationDate:
          typeof report.dateNotification === 'string'
            ? new Date(dateFRtoBasic(report.dateNotification))
            : report.dateNotification,
      },
      lastMonthRevenue: {
        netTax: report.netFiscal,
        netPayable: report.netAPayer,
        hoursWorked: report.nbHeuresTravaillees,
        unemploymentInsuranceGross: report.brutAC,
        untaxedGrossIncome: report.brutDeplafonne,
        unusedHoursDIF: report.nbDif,
        noticeDuration: report.dureePreavis,
        compensationAmount: report.montantIndemnite,
        netHourlyWages: report.salaireHoraireNet,
      },
      complementaryInfos: {
        notice: {
          noticeType: report.typePreavis,
          noticeStartDate:
            typeof report.debutPreavis === 'string'
              ? new Date(dateFRtoBasic(report.debutPreavis))
              : report.debutPreavis,
          noticeEndDate:
            typeof report.finPreavis === 'string' ? new Date(dateFRtoBasic(report.finPreavis)) : report.finPreavis,
        },
        specialStatus: report.statutParticulier,
        transaction: report.transaction,
        pensionPortability: report.portabilitePrevoyance,
      },
    }
    return reportForStore
  }

  static storingWorkStoppageDataFromAPI(report: WorkStoppageReportPayload): WorkStoppage {
    const reportForStore: WorkStoppage = {
      startOfWorkStoppage: report.dateDebut ? new Date(dateFRtoBasic(report.dateDebut)) : null,
      resumingDate: report.dateReprise ? new Date(dateFRtoBasic(report.dateReprise)) : null,
      reasonOfWorkStoppage: report.motifArret,
      resumingReason: report.motifReprise!,
      accidentDate: report.dateAccident ? new Date(dateFRtoBasic(report.dateAccident)) : null,
      subrogation: report.subrogation,
      subrogationStartDate: report.debutSubrogation ? new Date(dateFRtoBasic(report.debutSubrogation)) : null,
      subrogationEndDate: report.finSubrogation ? new Date(dateFRtoBasic(report.finSubrogation)) : null,
      employerIBAN: report.ibanSubrogation,
      employerBIC: report.bicSubrogation,
      collectorSiret: report.siretCollecteurSubrogation,
    }
    return reportForStore
  }

  static constructReportEndingContractPayload(
    generalInformations: GeneralInformations,
    lastMonthRevenue: LastMonthRevenue,
    complementaryInfos: ComplementaryInfos,
  ): EndingContractReportPayload {
    return {
      dateFin: generalInformations.endingDate ? dateToFRFormat(generalInformations.endingDate) : null,
      motifFin: generalInformations.endingReason,
      dateNotification: generalInformations.notificationDate
        ? dateToFRFormat(generalInformations.notificationDate)
        : null,
      dernierJourTravaille: generalInformations.lastDayWork ? dateToFRFormat(generalInformations.lastDayWork) : null,
      transaction: complementaryInfos.transaction,
      portabilitePrevoyance: complementaryInfos.pensionPortability,
      nbDif: lastMonthRevenue.unusedHoursDIF,
      dureePreavis: lastMonthRevenue.noticeDuration,
      montantIndemnite: lastMonthRevenue.compensationAmount,
      salaireHoraireNet: lastMonthRevenue.netHourlyWages,
      statutParticulier: complementaryInfos.specialStatus,
      typePreavis: complementaryInfos.notice.noticeType,
      debutPreavis: complementaryInfos.notice.noticeStartDate
        ? dateToFRFormat(complementaryInfos.notice.noticeStartDate)
        : null,
      finPreavis: complementaryInfos.notice.noticeEndDate
        ? dateToFRFormat(complementaryInfos.notice.noticeEndDate)
        : null,
      brutDeplafonne: lastMonthRevenue.untaxedGrossIncome!,
      brutAC: lastMonthRevenue.unemploymentInsuranceGross!,
      netFiscal: lastMonthRevenue.netTax!,
      netAPayer: lastMonthRevenue.netPayable!,
      nbHeuresTravaillees: lastMonthRevenue.hoursWorked!,
      dateLicenciement: generalInformations.firingDate ? dateToFRFormat(generalInformations.firingDate) : null,
      dateConvention: generalInformations.conventionDate ? dateToFRFormat(generalInformations.conventionDate) : null,
    }
  }

  static constructReportWorkStoppagePayload(
    workStoppage: WorkStoppage,
    isResuming: boolean,
  ): WorkStoppageReportPayload {
    return {
      nature: isResuming ? REPORT_TYPE.DSN_REPRISE_SUITE_ARRET_TRAVAIL : REPORT_TYPE.DSN_ARRET_TRAVAIL,
      dateDebut: workStoppage.startOfWorkStoppage ? dateToFRFormat(workStoppage.startOfWorkStoppage) : '',
      dateReprise: isResuming && workStoppage.resumingDate ? dateToFRFormat(workStoppage.resumingDate) : '',
      motifArret: workStoppage.reasonOfWorkStoppage,
      dateAccident: workStoppage.accidentDate ? dateToFRFormat(workStoppage.accidentDate) : '',
      subrogation: workStoppage.subrogation,
      debutSubrogation: workStoppage.subrogationStartDate ? dateToFRFormat(workStoppage.subrogationStartDate) : '',
      finSubrogation: workStoppage.subrogationEndDate ? dateToFRFormat(workStoppage.subrogationEndDate) : '',
      ibanSubrogation: workStoppage.employerIBAN,
      bicSubrogation: workStoppage.employerBIC,
      siretCollecteurSubrogation: workStoppage.collectorSiret,
      motifReprise: isResuming ? workStoppage.resumingReason : null,
    }
  }
}
