export type CompanyInfos = {
  siren: string
  nic: string
  companyName: string
  selectedApenCode: string | null
  selectedCollectiveConvention: string | null
  selectedRegimeApplied: string | null
  selectedOpcoRef: string | null
  payrollDeferral: boolean
}

export type Locality = {
  zipCode: string
  selectedCity: CitiesSuggestion | null
  country: string
  isForeign: boolean
  address: Address
}

export type Address = {
  street: string
  addressSupplement: string
  namedPlace: string
}

export type ObjKeyValueString = {
  key: string
  value: string
}

export type CitiesSuggestion = {
  localite: string
  departement: string
  codeDepartement: string
  region: string
  codeRegion: string
  codesPostal: string
  codeInsee: string
  created_at?: Date
  updated_at?: Date
  id?: number
}

export enum SOCIAL_ORGANIZATIONS {
  AGIRC_ARRCO = '01',
  CIBTP = '14',
  CONGES_SPECTACLES_AUDIENS = '05',
  DGFIP = '09',
  ORGANISME_COMPLEMENTAIRE = '15',
  FRANCE_TRAVAIL = '12',
  URSSAF = '13',
  OTHER_TAX_LIABILITY = '001',
}

export enum PAYMENT_METHOD {
  SEPA = '1',
  CHEQUE = '3',
  VIREMENT = '2',
}

export enum PAYMENT_METHOD_PLUS {
  CHEQUE_VIREMENT = '2',
}

export enum PAYMENT_METHOD_LABEL {
  SEPA = 'Prélèvement SEPA',
  CHEQUE = 'Chèque',
  VIREMENT = 'Virement',
  CHEQUE_VIREMENT = 'Chèque / Virement',
}

export enum FREQUENCY {
  MENSUEL = 'P_MEN',
  TRIMESTRIEL = 'P_TRI',
}

export enum FREQUENCY_LABEL {
  MENSUEL = 'Mensuel',
  TRIMESTRIEL = 'Trimestriel',
}

export type Organization = {
  code: string
  libelle: string
  showFrequency?: boolean
  frequency: 'P_MEN' | 'P_TRI' | null
  showPaymentMethod?: boolean
  paymentMethod: '1' | '2' | '3' | null
  other?: string | string[] | null
  showOther?: boolean
  isError?: boolean
  paymentMethodOptions?: ObjKeyValueString[]
}

export type PreExistingFields = {
  siret: string
  raisonSociale: string
  codeApen: string
  codePostal: string
  codeInsee: string
  adresse1: string
}

export type RiskCode = ObjKeyValueString & {
  taux: number
}

export type GeneralInformationsPayload = {
  raisonSociale: string
  siret: string
  cp: string
  numeroVoie: string
  localite: string
  codePays: string
  codeApen: string | null
  complementAdresse: string
  complementAdresse2: string
  firmeEtrangere: boolean
  ccn: string | null
  regime: string | null
  codeOpco: string | null
  decalagePaie: boolean
}

export type ContactPayload = {
  codeCivilite: string
  nomPrenom: string
  email: string
  tel: string
}

export type SocialOrganizationAPI = {
  code: string
  frequencePaiement: 'P_MEN' | 'P_TRI' | null
  methodePaiement: '1' | '2' | '3' | null
  autre: string | null
}

export type SocialOrganizationPayload = {
  organismes: SocialOrganizationAPI[]
  rib: {
    bic?: string | null
    iban?: string | null
  }
}

export type TaxationPayload = {
  taxationsSelectionnees: string[]
  dateCloture: string
}

export type WorkRateAccidentPayload = {
  tauxAt: number
  codeRisque: string
  tauxAtSupplementaire: number | null
}

export type Options = {
  apenCodes: ObjKeyValueString[]
  regimes: ObjKeyValueString[]
  collectiveConventions: ObjKeyValueString[]
  opcoRefs: string[]
  civilities: ObjKeyValueString[]
  organizations: Organization[]
  cibtpReferences: ObjKeyValueString[]
  complementaryPensionReferences: ObjKeyValueString[]
  taxations: ObjKeyValueString[]
  riskCodes: RiskCode[]
  eligiblePeriods: EligiblePeriod[]
}

export type Company = {
  id: number | null
  generalInformations: {
    companyInfos: CompanyInfos
    locality: Locality
  }
  contact: {
    name: string
    civility: string
    email: string
    phoneNumber: string
  }
  socialOrganizations: {
    selected: Organization[]
    rib: {
      iban?: string
      bic?: string
    }
    configurationSheet?: SocialOrganisationConfigurationSheet
  }
  taxation: {
    yearEndClosing: Date
    selectedTaxations: string[]
  }
  workAccidents: {
    riskCode: string | null
    effectiveAccidentRate: number
    additionalAccidentRate: number | null
  }
  status: boolean
}

export type CompanyDetails = {
  accidentTravail: WorkRateAccidentPayload
  contact: ContactPayload
  fiscalite: TaxationPayload
  informationsGenerales: GeneralInformationsPayload & { id: number }
  organimesSociaux: SocialOrganizationPayload
  status: boolean
}

export type SocialOrganisationConfigurationSheet = {
  ficheEtablissements: CompanyFiles[]
  ficheIndividuelles: IndividualFiles[]
}

export type CompanyFiles = {
  codeOrganisme: string
  details: { codeCotisationEtablissement: string; libelleCotisationEtablissement: string }[]
  libelleContrat: string
  libelleOrganisme: string
  referenceContrat: string
}

export type IndividualFiles = {
  codeOrganisme: string
  codePopulation: string
  details: string[]
  historiqueDetails: { annee: number; details: string[] }[]
  libelleContrat: string
  libelleOrganisme: string
  libellePopulation: string
  referenceContrat: string
  identifiantTechniqueAdhesion: string
  codeDelegataireGestion?: string | null
  codeOption?: string | null
}

export type EligiblePeriod = {
  period: string
  readablePeriod: string
  defaultPeriod: boolean
}
