<script setup lang="ts">
import { useVgt } from 'vue-guided-tour'
import { isMobileDevice } from '@/utils/index'
import { onMounted, ref } from 'vue'
import { useMainStore } from '@/stores/main'

const onboardingTour = useVgt()
const mainStore = useMainStore()

const steps = ref([
  {
    title: 'Bienvenue sur la nouvelle interface TeleDSN !',
    content: 'Laissez-nous vous présenter en quelques étapes comment naviguer dans cette interface et gérer vos DSN',
    popover: {
      width: '550px',
    },
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_2')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-1',
    popover: {
      width: '350px',
    },
    overlay: {
      allowInteraction: false,
    },
    content:
      'Accédez immédiatement à votre tableau de bord et à la liste de vos déclarations à l’aide de ce bouton toujours visible.',
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_3')
    },
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_1')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },

  {
    target: '.onboarding-step-2',
    popover: {
      width: '350px',
    },
    overlay: {
      allowInteraction: false,
    },
    content: 'Le tableau de bord vous permet d’accéder rapidement à vos déclarations, signalements et synthèses.',
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_4')
    },
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_2')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-3',
    popover: {
      width: '350px',
    },
    overlay: {
      allowInteraction: false,
    },
    content:
      'L’entreprise sur laquelle vous travaillez est visible dans ce menu. Dans le cas où vous gérez plusieurs entreprises, basculez d’une entreprise à l’autre avec ce menu.',
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_5')
    },
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_3')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-4 .p-splitbutton-defaultbutton',
    popover: {
      width: '550px',
    },
    overlay: {
      allowInteraction: false,
    },
    content: 'Accédez à la fiche entreprise sur laquelle vous travaillez à l’aide de ce raccourci.',
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_6')
    },
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_4')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-4 .p-splitbutton-menubutton',
    popover: {
      width: '550px',
    },
    overlay: {
      allowInteraction: false,
    },
    content:
      'Un menu contextuel vous permet d’accéder à la création de nouveaux salariés et contrats, ou d’une nouvelle entreprise.',
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_6')
    },
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_4')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-5',
    content: 'Un raccourci toujours visible vous emmène directement à la liste de vos salariés et contrats.',
    popover: {
      width: '350px',
    },
    overlay: {
      allowInteraction: false,
    },
    onBeforeNext: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_7')
    },
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_5')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
  {
    target: '.onboarding-step-6',
    popover: {
      width: '350px',
    },
    overlay: {
      allowInteraction: false,
    },
    content:
      'Le bouton « Mon compte » vous permet d’accéder à vos abonnements, vos factures et gérer vos entreprises et vos identifiants. Le menu contextuel à droite offre d’autres options comme la déconnexion.',
    onBeforePrev: () => {
      mainStore.recordLastStepOnBoardingTour('STEP_6')
    },
    onBeforeExit: () => {
      mainStore.recordLastStepOnBoardingTour('CANCELLED')
    },
  },
])

onMounted(() => {
  if (mainStore.triggerOnBoardingTour && !isMobileDevice()) {
    onboardingTour.start(0)
  }
})
</script>
<template>
  <vue-guided-tour :steps="steps">
    <template #nav="{ isFirstStep, isLastStep }">
      <div class="vgt__nav">
        <Button
          v-if="isFirstStep"
          @click="onboardingTour.exit"
          label="Peut-être plus tard"
          severity="secondary"
          size="small"
          class="mr-2"
        />
        <Button
          v-if="!isFirstStep"
          @click="onboardingTour.prev"
          label="Retour"
          severity="secondary"
          size="small"
          class="mr-2"
        />
        <Button v-if="isLastStep" @click="onboardingTour.exit" label="Quitter" size="small" class="ml-2" />
        <Button
          v-else
          @click="onboardingTour.next"
          :label="isFirstStep ? 'Commencer' : 'Suivant'"
          size="small"
          class="ml-2"
          iconPos="right"
          :icon="isFirstStep ? 'pi pi-play' : 'pi pi-chevron-right'"
        />
      </div>
    </template>
    <template #content="{ stepIndex, step }">
      <div class="p-2">
        <h3 v-if="step.title">{{ step.title }}</h3>
        <div :class="stepIndex === 0 ? '' : 'mt-3'">{{ step.content }}</div>
      </div>
    </template>
    <template #close>
      <div class="flex justify-content-end">
        <Button
          text
          rounded
          style="color: #6b7280 !important"
          class="absolute p-dialog-header-icon p-dialog-header-close p-link close-btn"
          @click="onboardingTour.exit"
          icon="pi pi-times"
          severity="contrast"
        />
      </div>
    </template>
  </vue-guided-tour>
</template>
<style scoped>
.close-btn {
  top: 3px;
  right: 0px;
}
</style>
