<script setup lang="ts">
import { useVModel } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useCompanyStore } from '@/stores/company'
import { validateEmailFormat } from '@/utils'
import { useMainStore } from '@/stores/main'
import ServorError from './ServorError.vue'

type Props = {
  visible: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['update:visible'])
const mainStore = useMainStore()
const companyStore = useCompanyStore()
const visible = useVModel(props, 'visible', emit)

const contact = ref(companyStore.company.contact)
const emailObject = ref('')
const formSubmitted = ref(false)
const emailObjectOptions = ref([
  "Demande d'assistance",
  "Demande d'informations",
  'Demander la suppression de mon compte utilisateur',
  'Demander la suppression de mon entreprise',
  'Partenariat/Affiliation',
  'Signaler une erreur ou un problème',
])
const messageToSend = ref('')
const companyId = computed(() => companyStore.company.id)
const emptyField = computed(() => !messageToSend.value || !emailObject.value || !contact.value)
const checkEmailFormat = computed(() => validateEmailFormat(contact.value.email))
const loading = computed(() => mainStore.subLoading)
const additionalMessages = computed(() => {
  const messages = []

  if (emptyField.value) messages.push('Un ou plusieurs champs sont manquants')
  if (!checkEmailFormat.value) messages.push('Votre adresse email doit être une adresse mail valide')

  return messages
})

const sendMessage = async () => {
  formSubmitted.value = true
  if (!emptyField.value && checkEmailFormat.value) {
    await mainStore.sendSupportMessage({
      feedbackEmail: contact.value.email,
      feedbackObjet: emailObject.value,
      feedbackRequete: messageToSend.value,
      feedbackTelephone: contact.value.phoneNumber,
      entrepriseId: companyId.value!,
    })
    visible.value = false
  }
}
</script>

<template>
  <Dialog v-model:visible="visible" modal header="Assistance">
    <div class="flex flex-column gap-5">
      <p>
        Une question ? Envoyez-nous un message à l'aide du formulaire ci-dessous ou appelez-nous au :
        <br /><b>09 72 46 44 20</b> (numéro non surtaxé)
      </p>
      <span class="p-float-label">
        <InputText
          class="w-full"
          :class="{ 'p-invalid': (!checkEmailFormat || !contact.email) && formSubmitted }"
          id="email"
          v-model="contact.email"
          placeholder="Votre adresse email"
          :disabled="loading"
        />
        <label for="email">Votre adresse email</label>
      </span>
      <span class="p-float-label w-full">
        <InputMask id="basic" v-model="contact.phoneNumber" mask="9999999999" :disabled="loading" />
        <label for="phoneNumber">Votre numéro de téléphone</label>
      </span>
      <span class="p-float-label w-full">
        <Dropdown
          id="emailObject"
          v-model="emailObject"
          :options="emailObjectOptions"
          class="w-full"
          placeholder="Sélectionnez l'objet de votre message"
          :class="{ 'p-invalid': !emailObject && formSubmitted }"
          :disabled="loading"
        />
        <label for="emailObject">Objet du message</label>
      </span>
      <span class="p-float-label">
        <TextAreaPrimevue
          :class="{ 'p-invalid': !messageToSend && formSubmitted }"
          class="w-full"
          v-model="messageToSend"
          rows="5"
          cols="30"
          :disabled="loading"
        />
        <label>Message</label>
      </span>
      <ServorError
        v-if="formSubmitted && (emptyField || mainStore.serverHaveReturnedError || !checkEmailFormat)"
        :additionalMessages="additionalMessages"
      />
    </div>
    <template #footer>
      <Button
        @click="visible = false"
        label="Retour"
        severity="secondary"
        :loading="loading"
        class="p-button-sm-mobile"
      />
      <Button @click="sendMessage" label="Envoyer" severity="primary" :loading="loading" class="p-button-sm-mobile" />
    </template>
  </Dialog>
</template>
