import { ObjKeyValueString } from '@/models/main.model'
import { IndividualFiles } from './company.model'

export type GlobalInformations = {
  contractType: string | null
  startDate: Date | null
  endDate: Date | null
  label: string
  workPlace: string
}

export type SocioProfessionalCategory = {
  group: string | null
  category: string | null
  complement: string | null
  section: string | null
  jobClassification: string | null
  ableToContributeToExecutiveRetirement: { loading: boolean; result: boolean | null }
}

export type Options = {
  contractTypes: ObjKeyValueString[]
  hiringReasonOptions: ObjKeyValueString[]
  publicAssistancePolicies: ObjKeyValueString[]
  casualEntertainmentWorkerStatus: ObjKeyValueString[]
  diplomas: ObjKeyValueString[]
  categories: ObjKeyValueString[]
  groups: ObjKeyValueString[]
  complements: ObjKeyValueString[]
  sections: ObjKeyValueString[]
  jobClassifications: ObjKeyValueString[]
  workingUnitOptions: ObjKeyValueString[]
  workingTimeModeOptions: ObjKeyValueString[]
  complementaryPensions: ObjKeyValueString[]
  professionalExpenseRates: ObjKeyValueString[]
  disabledWorkers: ObjKeyValueString[]
  arduousExposures: ObjKeyValueString[]
  riskFactors: ObjKeyValueString[]
  workerAbroads: ObjKeyValueString[]
  workAccidentRates: {
    ordreTauxAt: number
    taux: number
  }[]
}

export enum SPECIFIC_STATUS {
  EMPOYEE_MANAGER = 'Dirigeant Salarié',
  HOME_HELP_FOR_FRAGILE_PEOPLE = 'Aide à Domicile Personne Fragile',
  PRIVATE_CIVIL_SERVICE_AGENT = 'Agent Privé de la Fonction Publique',
  EMPLOYEE_APPRENTICE = 'Apprenti Salarié',
  INTERMITTENT = 'Intermittent',
}

export type Contract = {
  id?: number | null
  contractNumber: string | null
  active: boolean | null
  globalInformations: GlobalInformations
  statusSpecifications: StatusSpecifications
  socioProfessionalCategory: SocioProfessionalCategory
  workingHoursAndWages: WorkingHoursAndWages
  additionalInformations: AdditionalInformations
  deletable?: boolean
  complementaryOrgMemberships: IndividualFiles[]
}

export type GeneralInformationsContractPayload = {
  nature: string
  dateDebut: string
  dateFin: string | null
  libelleEmploi: string
  codeInseeLieuTravail: string
}

export type StatusSpecifications = {
  specificStatus: string | null
  publicAssistancePolicy: string | null
  preparedTraining: string | null
  hiringReason: string | null
  casualEntertainmentWorkerType: string | null
}

export type StatusSpecificationsPayload = {
  nature: string | null
  dirigeantSalarie: boolean
  agentPriveFonctionPublique: boolean
  apprentiSalarie: boolean
  typeIntermitentSpectacle: string | null
  diplomeApprenti: string | null
  motifRecours: string | null
  dispositifAidePublique: string | null
  aideDomicilePersonneFragile: boolean
}

export type CategoriesPayload = {
  nature: string | null
  dirigeantSalarie: boolean
  typeIntermitentSpectacle: string | null
  famillePcsEse: string
  branchePcsEse: string | null
  pcsEse: string
  complementPcsEse: string | null
  classificationMetier: string | null
}

export type WorkingHoursAndWages = {
  workingUnit: string | null
  monthlyContractHour: number | null
  monthlyLegalCompanyHours: number | null
  monthlyGrossWage: number | null
  workingTimeMode: string | null
}

export type WorkingHoursAndWagesPayload = {
  nature: string
  numero: string
  uniteMesure: string
  quotiteEntreprise: number
  quotiteContrat: number
  pcsEse: string
  modaliteTemps: string
  brutMensuel: number
}

export type AdditionalInformations = {
  complementaryPension: string | null
  professionalExpenseRate: number | null
  noUnemploymentInsuranceContribution: boolean
  additionalContributionAgingRisk: boolean
  workerAbroad: string | null
  arduousness: string | null
  riskFactor: string[] | null
  disabledWorker: string | null
  workAccidentRate: number | null
}

export type AdditionalInformationsPayload = {
  retraiteComplementaire: string
  tauxFraisProfessionnels: number
  pasAssuChomage: boolean
  tpsPartielCotizComplemRisqueVieillesse: boolean
  detacheExpatrie: string
  penibilite: string
  facteursRisquePenibilite: string[] | null
  codeTravailleurHandicape: string
  modaliteTemps: string
  ordreTauxAt: number
}

export type ContractPayload = {
  id: number
  actif: boolean
  numero: string
  categoriesSocioProfessionnelles: CategoriesPayload
  etapeStatus: StatusSpecificationsPayload
  informationsComplementaires: AdditionalInformationsPayload
  informationsGenerales: GeneralInformationsContractPayload
  tempsTravailEtSalaire: WorkingHoursAndWagesPayload
  affiliationsOrganismesComplementaires: ComplementaryOrgMembershipsPayload[]
}

export type ComplementaryOrgMembershipsPayload = {
  identifiantTechniqueAdhesion: string
  codeOrganisme: string
  codePopulation: string
  codeDelegataireGestion?: string | null
  referenceContrat: string
  codeOption?: string | null
}

export enum ContractStatus {
  ACTIVE = 'Actif',
  FINISHED = 'Terminé',
  BEING_CREATED = 'En cours de création',
}

export enum CONTRACT_TYPE {
  CDI = '01',
  CDD = '02',
  OTHERS = '90',
  INTERNSHIP_CONVENTION = '29',
  SOCIAL_MANDATE = '80',
  CSAT = '70',
  CAPE = '32',
  CEE = '60',
  CIVIC_SERVICE_VOLUNTEERING = '89',
}

export enum WORKING_UNIT {
  HOUR_PACKAGE = '21',
  DAY_PACKAGE = '20',
  HOUR = '10',
  DAYTIME = '12',
}

export enum GROUPEMENT {
  ENTREPRENEUR = '03',
  EXECUTIVES_AND_HIGHER_INTELLECTUAL_PROFESSIONS = '04',
}

export enum WORKING_TIME_MODE {
  NOT_CONCERNED = '99',
}
