<script setup lang="ts">
import { useEmployeeStore } from '@/stores/employee'
import { useVModel } from '@vueuse/core'
import { router } from '@/routes'
import { Router } from '@/models/router.model'
import SelectEmployeeDropdown from '../employee/SelectEmployeeDropdown.vue'
import { computed, ref, watch } from 'vue'
import { useContractStore } from '@/stores/contract'
import { useRoute } from 'vue-router'

type Props = {
  visible: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['update:visible'])

const route = useRoute()
const employeeStore = useEmployeeStore()
const contractStore = useContractStore()
const visible = useVModel(props, 'visible', emit)

const employee = computed(() => employeeStore.employee)
const haveEmployees = computed(() => employeeStore.employees.length > 0)
const showActiveContractWarning = ref(false)

watch(employee.value, () => {
  if (employee.value.id) {
    showActiveContractWarning.value = employeeStore.hasActiveContract(employee.value.id)
  }
})

const goToAddContract = () => {
  if (route.path !== Router.CONTRACT + Router.STEP_GENERAL_INFORMATIONS) {
    contractStore.$reset()
  }
  router.push(Router.CONTRACT + Router.STEP_GENERAL_INFORMATIONS)
  visible.value = false
}

const goToAddEmployee = () => {
  visible.value = false
  router.push(Router.EMPLOYEE)
}
</script>

<template>
  <Dialog v-model:visible="visible" header="Ajouter un contrat" modal>
    <SelectEmployeeDropdown
      v-if="haveEmployees"
      placeholder="Veuillez sélectionner un salarié auquel rattacher un contrat"
      label="Sélectionner un salarié"
      class="mt-4"
    />
    <Message v-else :closable="false">
      Vous n'avez pas de salarié dans l'entreprise. Voulez-vous ajouter un salarié ?
    </Message>
    <InlineMessage v-if="showActiveContractWarning" severity="warn" class="mt-5">
      Vous vous apprêtez à ajouter un nouveau contrat alors que le dernier contrat est toujours actif. Poursuivre ?
    </InlineMessage>
    <template #footer>
      <Button @click="visible = false" label="Retour" severity="secondary" class="p-button-sm-mobile" />
      <Button
        v-if="haveEmployees"
        @click="goToAddContract()"
        :disabled="employeeStore.employee.id === null"
        label="Ajouter un contrat"
        severity="primary"
        class="p-button-sm-mobile"
      />
      <Button
        v-else
        @click="goToAddEmployee"
        label="Ajouter un salarié"
        severity="primary"
        class="p-button-sm-mobile"
      />
    </template>
  </Dialog>
</template>
